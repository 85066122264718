import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useSessionStore } from 'session/useSessionStore';
import { LoginLoader } from './LoginLoader';
import { OnLogoutContext } from './OnLogoutContext';

export const OktaRestrictedRoute = () => {
    const {authState, oktaAuth} = useOktaAuth();

    const {jwt, setJwt} = useSessionStore(state => ({
        jwt: state.jwtToken,
        setJwt: state.setJwtToken
    }));

    const checkAuthentication = useCallback(async () => {
        //const sessionExists = await oktaAuth.session.exists();

        if (!oktaAuth) {
            return;
        }
        if (!!authState) {
            const authenticated = !!authState?.isAuthenticated;
            if (!authenticated) {
                const originalUri = toRelativeUrl(window.location.href, window.location.origin);
                oktaAuth.setOriginalUri(originalUri);
                oktaAuth.signInWithRedirect();
            }
        }
    }, [oktaAuth, authState]);

    const logout = useCallback(async () => {
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        oktaAuth.setOriginalUri(originalUri);

        //oktaAuth.tokenManager.clear();

        const sessionExists = await oktaAuth.session.exists();
        if (sessionExists) {
            await oktaAuth.session.close();
        }

        await oktaAuth.signOut();
        //await oktaAuth.authStateManager.updateAuthState();
    } , [oktaAuth, authState]);

    useEffect(() => {
        if (!!authState?.isAuthenticated && authState?.accessToken && !!oktaAuth) {
            setJwt(authState.accessToken.accessToken);
            console.log(authState.accessToken.accessToken);
        }
    }, [authState]);

    useEffect(() => { checkAuthentication() }, [oktaAuth,, authState, !!authState, authState?.isAuthenticated]);

    /*useEffect(() => {

    }, [jwt, setJwt]);*/

    useEffect(() => {
        console.log('is Authenticated test -------------->' + (!!authState?.isAuthenticated && authState?.accessToken && !!oktaAuth));
    }, [authState?.accessToken, authState?.isAuthenticated, oktaAuth]);

    useEffect(() => {
        const syncLogout = (event: StorageEvent) => {
            if (event.key === 'logout') {
                oktaAuth.signOut();
            }
        };

        window.addEventListener('storage', syncLogout);

        return () => {
            window.removeEventListener('storage', syncLogout);
        };
    }, [oktaAuth]);

    return (
        <React.Fragment>
            {
                (!!authState?.isAuthenticated && authState?.accessToken && !!oktaAuth) ?
                    <OnLogoutContext.Provider value={logout}>
                        <Outlet/>
                    </OnLogoutContext.Provider>
                    :
                    <LoginLoader/>
            }
        </React.Fragment>

    );
};