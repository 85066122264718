import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import ImageLayer from 'ol/layer/Image';

import { useSessionStore } from 'session/useSessionStore';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { SeismicMiniMapContent } from './SeismicMiniMapContent';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { GeopostWMSSource } from 'features/seismic/models/classes/layers/GeopostWMSSource';
import { useAPI } from 'hooks/useAPI';
import { IMiniMap } from 'features/seismic/models/interfaces/IMiniMap';
import { Position } from 'components/geopostPopup/models/enums/Position';
import { ApiType } from 'models/enums/ApiType';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';

export function SeismicMiniMapPopup ({ opened, setOpened, dataGeometryLine }:Props){
    const { t } = useTranslation();
    const allLayers = useRef<ImageLayer<GeopostWMSSource>[]>([]);
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    const { featureFlags } = useFeatureFlags();

    const { execute } = useAPI<IMiniMap[]>('/SeismicLayers/GetAllLayers');

    useEffect(() => {
        const fetch = async () => {
            let dataMap = await execute({
                apiType: ApiType.LayersApi,
                params: {
                    permalinkToken: localStorage['lastAutoCreatedPermalink'] ? localStorage['lastAutoCreatedPermalink'] : ''
                }
            });
            if (dataMap && allLayers.current) {
                for (let i = 0; i < dataMap?.length; i++) {
                    let layer = new ImageLayer({
                        source: new GeopostWMSSource({
                            url: 'geoserver.geopost.com.br/geoserver/wms',
                            params: { LAYERS: dataMap[i].WorkspaceName + ':' + dataMap[i].LayerName },
                            ratio: 1,
                        }, jwtToken, tenantConfig?.endpoints.miniMapProxy ?? ''),
                    });
                    allLayers.current = [...allLayers.current, layer];
                }
            }
        };
        try {
            if (featureFlags?.seismic2D.Projections) {
                fetch();
            }
        }
        catch (e) {
            console.log('Error on get layers to minimap');
        }
    }, [featureFlags?.seismic2D.Projections]);

    return (
        <GeopostPopup
            temporaryIgnoreFlexInContainer
            open={opened}
            title={t('miniMap')}
            onChangeOpen={value => setOpened(value)}
            overlay={false}
            height={211}
            width={398}
            position={Position.topRight}
            customYPosition={55}
            rebuildOnOpen={false}
        >
            <SeismicMiniMapContent opened={opened} dataGeometryLine={dataGeometryLine} allLayers={allLayers.current} />
        </GeopostPopup>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    dataGeometryLine: ILineString | null;
}