import queryString from 'query-string';
import Cookies from 'universal-cookie';

import { EAuthenticationType } from '../models/enums/EAuthenticationType';

export function getUrlParams(){
    const cookies = new Cookies();
    const element = queryString.parse(window.location.search, {});
    let jwtToken = (element.sessionJwt) ? element.sessionJwt as string : '';

    if (jwtToken === '') {
        const tenantToken = (element.tenantToken)?element.tenantToken as string:'';
        try {
            const cookieName = 'auth_spa_' + tenantToken;
            let cookieValue = cookies.get(cookieName);
            jwtToken = cookieValue;
        } catch (error) {
            console.log('Error on getting cookie spa: geopost auth');
        }
    }

    let type = (element.type) ? element.type as string : '';
    let layerId = (element.layerId) ? element.layerId as string : '';
    let featureId = (element.featureId) ? element.featureId as string : '';
    let volumeToken = (element.volumeToken) ? element.volumeToken as string : '';
    let forceDisableCache = (element.forceDisableCache) ? element.forceDisableCache.toString().toLowerCase() === 'true' : false;
    let isVolume = (element.isVolume) ? Boolean(element.isVolume) as boolean : false;
    let tenantToken = (element.tenantToken) ? element.tenantToken as string : '';
    let totalLoadingIterations = (element.totalLoadingIterations) ? parseInt(element.totalLoadingIterations as string) : 1;
    let traceDecimation = (element.traceDecimation) ? parseInt(element.traceDecimation as string) : 1;
    let lineLoadingStep = (element.lineLoadingStep) ? parseInt(element.lineLoadingStep as string) : 1;
    let requestMsOverhead = (element.requestMsOverhead) ? parseInt(element.requestMsOverhead as string) : 100;
    let externalSSOAccessToken = (element.externalSSOAccessToken)?element.externalSSOAccessToken as string:'';
    let authType = (element.authType) ? parseInt(element.authType as string) : EAuthenticationType.Geopost;
    let gridToken = (element.gridToken) ? element.gridToken as string: '';
    let performanceMonitoring = (element.performanceMonitoring) ? (element.performanceMonitoring as string).toLowerCase() === 'true' : false;
    let development = (element.development) ? (element.development as string).toLowerCase() === 'true' : false;
    let prepareRenderStep = (element.prepareRenderStep) ? parseInt(element.prepareRenderStep as string) : 10;

    let sourceApi = (element.sourceApi) ? element.sourceApi as string : '';
    return {
        jwtToken,
        type,
        layerId,
        featureId,
        volumeToken,
        forceDisableCache,
        isVolume,
        tenantToken,
        totalLoadingIterations,
        sourceApi,
        lineLoadingStep,
        traceDecimation,
        requestMsOverhead,
        externalSSOAccessToken,
        authType,
        gridToken,
        performanceMonitoring,
        development,
        prepareRenderStep
    };
}

export const SEISMIC_API_URL = 'https://localhost:7066/';