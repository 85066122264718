import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { extent as olExtent } from 'openlayers';

import { useSessionStore } from 'session/useSessionStore';
import { ISurveyMetadata } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { useAPI } from 'hooks/useAPI';
import { useLine3DNavigationStore } from '../stores/useLine3DNavigationStore';
import { GeopostSeismicLayer } from '../models/classes/GeopostSeismicLayer';
import { SurveyType } from '../models/enums/SurveyType';
import { useLoadingTileStore } from '../stores/useLoadingTilesStore';
import { GeopostMap } from '../models/classes/GeopostMap';
import { IColorbar } from '../models/interfaces/IColorbar';
import { GeopostSeismicDebugLayer } from '../models/classes/GeopostSeismicDebugLayer';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';
import { SeismicCalculatorFactory } from '../models/classes/SeismicCalculatorFactory';
import { IGeopostSeismicLayerParams } from '../models/interfaces/IGeopostSeismicLayerParams';
import { LineType } from '../models/enums/LineType';
import { useGetRequirements } from '../components/projections/api/useSeismicController';
import { useTileSize } from './useTileSize';
import { usePerformanceMonitoringStore } from '../stores/usePerformanceMonitoringStore';
import { useFeatureFlags } from './useFeatureFlags';
import { getMetadataPerformanceDataFromAxiosResponse } from '../utils/performanceUtils';
import { usePrepareRender } from './usePrepareRender';
import { IErrorResponse } from '../models/interfaces/IErrorResponse';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { GeopostTileLoadingLayer } from '../models/classes/layers/GeopostTileLoadingLayer';
import { useZSliceStore } from '../stores/useZSliceStore';
import { IGeopostLineLayerParams } from '../models/interfaces/IGeopostLineLayerParams';
import { ZSliceTileSourceStrategy } from '../models/classes/TileSourceStrategies/ZSliceTileSourceStrategy';
import { LineTileSourceStrategy } from '../models/classes/TileSourceStrategies/LineTileSourceStrategy';
import { getSurveyExtentDistance, getXByTrace } from '../utils/calculateScreenValuesUtils';
import { useGetSliceRotation } from './useGetSliceRotation';
import { useGetResolutionsImperatively } from '../api/useSeismicResolutionController';

interface IMetadataResponse extends IErrorResponse {
    Metadata: ISurveyMetadata;
    Resolutions: number[];
    TileDimensions: number[];
    Colorbar: IColorbar
}

function createSeismicMetadataError (): ISurveyMetadata{
    return {
        Error: true,
        Attributes: [],
        DisplayName: '',
        Domain: 0,
        EBCDIC: '',
        IndexDate: '',
        IsIndexed: false,
        MaxSampleValue: 0,
        MinSampleValue: 0,
        Size: 0,
        SurveyToken: '',
        Type: 0,
        VolumePath: '',
        VolumeToken: '',
        DimensionNames: {
            Inline: '',
            Xline: '',
            Sample: '',
            Cdp: ''
        },
        Header: {
            AverageTraceDistance: 0,
            ByteSize: 0,
            FormatCode: 0,
            Length: 0,
            NumberOfTraces: 0,
            SampleInterval: 0,
            SampleIntervalUnit: 0,
            SamplesPerTrace: 0,
            TotalY: 0
        },
        Entitlement3DLimits: {
            InlineStart: 0,
            InlineEnd: 0,
            XlineStart: 0,
            XlineEnd: 0,
            TotalInlines: 0,
            TotalXlines: 0,
        },
        LoadingParams: {
            CoordinateX: 0,
            CoordinateY: 0,
            Inline: 0,
            ScalarCoordinateX: 0,
            ScalarCoordinateY: 0,
            ScalarX: 0,
            ScalarY: 0,
            SRID: 0,
            Xline: 0,
        },
        Survey3DInfo: {
            EntitledInlines: [],
            EntitledXlines: [],
            InlineEnd: 0,
            InlineIncrement: 0,
            InlineStart: 0,
            TotalInlines: 0,
            TotalXlines: 0,
            XlineEnd: 0,
            XlineIncrement: 0,
            XlineStart: 0
        }
    };
}

export function useStartMap(containerMap: RefObject<HTMLDivElement>) {
    const urlParams = getParamsFromUrl();
    const urlApi = '/Seismic/Volume/Metadata';
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    const setMetadataPerformanceData = usePerformanceMonitoringStore(state => state.setMetadataPerformanceData);
    const addTilePerformanceData = usePerformanceMonitoringStore(state => state.addTilePerformanceData);
    const addTileErrorData = usePerformanceMonitoringStore(state => state.addTilesWithError);

    const [error, setError] = useState('');

    const { execute: executeMainMetadata } = useAPI<IMetadataResponse>(urlApi);

    const { mutateAsync: getResolutions } = useGetResolutionsImperatively();

    const { setIsLoading: setSeismicIsLoading, scale, setAbsoluteGain, setSurveyMetadata, setVolumeToken, rangeTrace: stateRangeTrace, setRangeTrace,
        setColorbar, setCalculator: setSeismicCalculator, setRestrict, isInverted, setVolumeIdentifier, isAntialiasApplied } = useSeismicStore(state => ({
        rangeTrace: state.rangeTrace,
        scale: state.scale,
        isInverted: state.isInverted,
        isAntialiasApplied: state.isAntialiasApplied,
        setAbsoluteGain: state.setAbsoluteGain,
        setCalculator: state.setCalculator,
        setColorbar: state.setColorbar,
        setIsLoading: state.setIsLoading,
        setSurveyMetadata: state.setSurveyMetadata,
        setVolumeToken: state.setVolumeToken,
        setRangeTrace: state.setRangeTrace,
        setRestrict: state.setRestrict,
        setVolumeIdentifier: state.setVolumeIdentifier,
    }));

    const { lineTypeFromStore, setLineNumber, setLineType } = useLine3DNavigationStore(state => ({
        lineTypeFromStore: state.lineType,
        setLineNumber: state.setLineNumber,
        setLineType: state.setLineType
    }));

    const { dpi, jpgQuality, totalParallelProcess, maxTilesLoading, pixelRatio, initialZoom, zoomFactor,
        setLayers, setMap, setResolutions, pointerMoveObserver, mainLayer } = useMapStore(state => ({
        dpi: state.dpi,
        pointerMoveObserver: state.pointerMoveObserver,
        initialZoom: state.initialZoom,
        jpgQuality: state.jpgQuality,
        maxTilesLoading: state.maxTilesLoading,
        pixelRatio: state.pixelRatio,
        setLayers: state.setLayers,
        setMap: state.setMap,
        setResolutions: state.setResolutions,
        totalParallelProcess: state.totalParallelProcess,
        zoomFactor: state.zoomFactor,
        mainLayer: state.mainSeismicLayer
    }));

    const { mutateAsync: getRequirements } = useGetRequirements();

    const { increaseLoaded, increaseLoading, updatePercentage } = useLoadingTileStore(state => ({
        increaseLoaded: state.increaseLoaded,
        increaseLoading: state.increaseLoading,
        updatePercentage: state.updatePercentage
    }));

    const seismic2DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer2DDefaults);

    const tileSize = useTileSize();

    const prepareRender = usePrepareRender();
    const isPerformanceMonitoringEnabledByFlag = useFeatureFlags().featureFlags?.seismic2D.PerformanceMonitor;
    const { current: isPerformanceMonitoringEnabledByParam } = useRef(getParamsFromUrl().performanceMonitoring);
    const isPerformanceMonitoringEnabled = useMemo(() => isPerformanceMonitoringEnabledByParam, [isPerformanceMonitoringEnabledByParam]);

    const mapStartedRef = useRef(false);

    const handleTileRequestDebugData = useMemo(() => {
        if (urlParams.performanceMonitoring) {
            return (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, requestSendAt: Date) => {
                if (!!xhr.response) {
                    const queryApiTimeHeader = xhr.getResponseHeader('x-gp-debug-api-query-time');
                    const requestCameOnServerAtHeader = xhr.getResponseHeader('x-gp-debug-request-came-at');
                    const responseLeaveServerAtHeader = xhr.getResponseHeader('x-gp-debug-response-leave-at');
                    const plotTimeHeader = xhr.getResponseHeader('x-gp-debug-tile-plot-time');

                    const startSample = xhr.getResponseHeader('start-sample');
                    const endSample = xhr.getResponseHeader('end-sample');
                    const startTrace = xhr.getResponseHeader('start-trace');
                    const endTrace = xhr.getResponseHeader('end-trace');
                    const geopostSource = xhr.getResponseHeader('Geopost-Source');
                    const traceId = xhr.getResponseHeader('Geopost-Trace-Id');

                    if (!queryApiTimeHeader || !requestCameOnServerAtHeader || !responseLeaveServerAtHeader || !plotTimeHeader || !startSample || !endSample || !startTrace || !endTrace) {
                        console.log('A tile request doesn\'t have the required debug headers');
                        return;
                    }

                    const plotTime = parseFloat(plotTimeHeader);
                    const queryApiTime = parseFloat(queryApiTimeHeader);
                    const requestCameOnServerAt = new Date(requestCameOnServerAtHeader);
                    const responseLeaveServerAt = new Date(responseLeaveServerAtHeader);
                    const currentTime = new Date().getTime();
                    const processingTime = ((responseLeaveServerAt.getTime() - requestCameOnServerAt.getTime()) / 1000 - queryApiTime);
                    const requestNetworkTime = (requestCameOnServerAt.getTime() - requestSendAt.getTime()) / 1000;
                    const responseNetworkTime = (currentTime - responseLeaveServerAt.getTime()) / 1000;
                    const totalTime = (currentTime - requestSendAt.getTime()) / 1000;

                    addTilePerformanceData({
                        traceId: traceId ?? '',
                        source: geopostSource ?? '',
                        apiTime: parseFloat(queryApiTime.toFixed(3)),
                        lineNumber: lineNumber,
                        lineType: urlParams.lineType ?? lineTypeFromStore,
                        serverProcessingTime: parseFloat((processingTime - plotTime).toFixed(3)),
                        requestNetworkTime: parseFloat(requestNetworkTime.toFixed(3)),
                        responseNetworkTime: parseFloat(responseNetworkTime.toFixed(3)),
                        plotTime: plotTime,
                        totalTime: parseFloat(totalTime.toFixed(3)),
                        tileWidth: tileWidth,
                        startSample: parseFloat(startSample),
                        endSample: parseFloat(endSample),
                        startTrace: parseFloat(startTrace),
                        endTrace: parseFloat(endTrace)
                    });
                }
            };
        }
    }, [urlParams.performanceMonitoring, urlParams.lineType, addTilePerformanceData, lineTypeFromStore]);

    const handleTileRequestError = (xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, startTrace: number, endTrace: number) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            if (!e.target?.result){
                return;
            }

            const error = JSON.parse(e.target.result.toString()) as IResponseAPI<IErrorResponse>;

            if (error) {
                addTileErrorData({
                    errorMessage: error['Result']['ErrorName'] + ' - ' + error['Result']['Error'],
                    status: xhr.status,
                    lineNumber,
                    lineType: urlParams.lineType ?? lineTypeFromStore,
                    tileWidth,
                    startTrace,
                    endTrace,
                    fullErrorMessage: error.Result.Traceback
                });
            }
        };

        if (typeof xhr.response === 'string'){
            try {
                const error = JSON.parse(xhr.response) as IResponseAPI<IErrorResponse>;

                if (error) {
                    addTileErrorData({
                        errorMessage: error['Result']['ErrorName'] + ' - ' + error['Result']['Error'],
                        status: xhr.status,
                        lineNumber,
                        lineType: urlParams.lineType ?? lineTypeFromStore,
                        tileWidth,
                        startTrace,
                        endTrace,
                        fullErrorMessage: error.Result.Traceback
                    });
                }
            }
            catch (e){
                addTileErrorData({
                    errorMessage: 'Unknown error',
                    status: xhr.status,
                    lineNumber,
                    lineType: urlParams.lineType ?? lineTypeFromStore,
                    tileWidth,
                    startTrace,
                    endTrace,
                    fullErrorMessage: 'Unknown error'
                });
            }
        }
        else {
            fileReader.readAsText(xhr.response);
        }
    };

    const preparingCacheRef = useRef(false);

    const getSliceRotation = useGetSliceRotation();

    const handleTileRequestSuccess = useCallback((xhr: XMLHttpRequest, lineNumber: number, tileWidth: number, requestSendAt: Date) => {
        if (!urlParams.forceDisableSamplesCache && !!tenantConfig?.useSamplesMatrixCache) {
            const geopostSourceHeader = xhr.getResponseHeader('geopost-source');
            if (!!geopostSourceHeader && geopostSourceHeader.toLowerCase() === 'fly' && !!mainLayer && !preparingCacheRef.current) {
                const layerParams = mainLayer.getParams();
                preparingCacheRef.current = true;
                prepareRender(mainLayer, layerParams, layerParams.loadingLayer!, false, lineTypeFromStore, false);
                setTimeout(() => { preparingCacheRef.current = false }, 120000);
            }
        }
        if (!!handleTileRequestDebugData) {
            handleTileRequestDebugData(xhr, lineNumber, tileWidth, requestSendAt);
        }
    }, [handleTileRequestDebugData, lineTypeFromStore, mainLayer, prepareRender, urlParams.forceDisableSamplesCache]);

    useEffect(() => {
        if (!!mainLayer) {
            mainLayer.tileSourceStrategy.onSettleTileRequest = handleTileRequestSuccess;
        }
    }, [handleTileRequestSuccess, mainLayer]);

    useEffect(() => {
        const rectMap = containerMap.current?.getBoundingClientRect()!;
        const height = parseInt(rectMap.height.toString());
        const width = parseInt(rectMap.width.toString());

        if (!jwtToken) {
            //setSeismicIsLoading(false);
            return;
        }

        async function start() {
            setSeismicIsLoading(true);
            const requestLeaveAt = new Date();

            const lineTypeParam = getParamsFromUrl().lineType;

            const response = await executeMainMetadata({
                params: {
                    filePath: urlParams.filePath,
                    volumeToken: urlParams.volumeToken,
                    forceDisableCache: urlParams.forceDisableImageCache,
                    tileWidth: tileSize.width,
                    screenHeight: height,
                    includePercentageCalc: true,
                    calcResolutions: (lineTypeParam ?? lineTypeFromStore) !== LineType.ZSlice,
                    volumeIdentifier: urlParams.volumeIdentifier
                },
                headers: {
                    'x-gp-debug-active': urlParams.performanceMonitoring,
                    'x-gp-debug-request-leave-at': new Date().toUTCString()
                }
            }, (response) => {
                if (urlParams.performanceMonitoring) {
                    const performanceData = getMetadataPerformanceDataFromAxiosResponse(response, requestLeaveAt, urlParams.volumeToken);
                    if (!!performanceData) {
                        setMetadataPerformanceData(performanceData);
                    }
                }
            }, (error) => {
                let messageErrorUser = 'A critical error ocurred';

                if (error instanceof AxiosError){
                    const axiosError = error as AxiosError<IResponseAPI<IMetadataResponse>>;

                    const response = axiosError.response?.data?.Result;

                    if (response){
                        if (response.Error === 'Seismic not found'){
                            messageErrorUser = 'No file was found for the provided seismic.';
                        }
                    }

                    if (urlParams.performanceMonitoring) {
                        const performanceData = getMetadataPerformanceDataFromAxiosResponse(axiosError.response!, requestLeaveAt, urlParams.volumeToken);
                        if (!!performanceData) {
                            setMetadataPerformanceData(performanceData);
                        }
                    }
                }
                else {
                    setMetadataPerformanceData({
                        error: error.message,
                        apiTime: 0,
                        requestNetworkTime: 0,
                        responseNetworkTime: 0,
                        serverProcessingTime: 0,
                        totalTime: 0,
                        volumeToken: ''
                    });
                }

                setError(messageErrorUser);
                setSurveyMetadata(createSeismicMetadataError());
            });

            setVolumeIdentifier(urlParams.volumeIdentifier);

            if (response) {
                if (response.Error) {
                    setSurveyMetadata(createSeismicMetadataError());
                    setError(response.Error);
                }
                else {
                    const { needsFlip, rotation, needsVerticalFlip, reference: rotationReference } = await getSliceRotation(lineTypeFromStore, response.Metadata);
                    const restrict = await getRequirements({
                        surveyToken: response?.Metadata.SurveyToken,
                        tenantToken: urlParams.tenantToken,
                        volumeToken: urlParams.volumeToken
                    });
                    setRestrict(restrict);
                    const metadata = response.Metadata;

                    const { lineNumber: lineNumberParam, lineType: lineTypeParam } = getParamsFromUrl();

                    let lineType = lineTypeFromStore;

                    if (metadata?.Type === SurveyType.Seismic2D){
                        lineType = LineType.Line2D;
                    }

                    const seismicCalculator = SeismicCalculatorFactory.buildCalculator(metadata.IsIndexed, lineType, metadata.Type);
                    const rangeTrace = seismicCalculator.getInitialRangeTrace(metadata);

                    const lineIncrement = seismicCalculator.getLineIncrement(metadata);
                    const lineNumber = lineNumberParam ?? seismicCalculator.calculateLineStart(metadata);
                    const extent = seismicCalculator.calculateExtent(metadata, scale, rangeTrace);
                    const center = seismicCalculator.calculateCenter(extent, metadata, scale, rangeTrace);

                    let surveyLineStart = 0;
                    if (metadata.Survey3DInfo) {
                        surveyLineStart = lineType === LineType.Xline ? metadata.Survey3DInfo.InlineStart : metadata.Survey3DInfo.XlineStart;
                    }

                    let surveyLineEnd = 0;
                    if (metadata.Survey3DInfo) {
                        surveyLineEnd = lineType === LineType.Xline ? metadata.Survey3DInfo.InlineEnd : metadata.Survey3DInfo.XlineEnd;
                    }

                    const traceSkip = seismicCalculator.getSkipTrace(metadata);
                    let resolutions = response.Resolutions;
                    let tileDimensions = response.TileDimensions;
                    if (!resolutions || !tileDimensions) {
                        ({ Resolutions: resolutions,  TileDimensions: tileDimensions} = await getResolutions({
                            scaleY: scale.y,
                            screenHeight: height,
                            tileWidth: tileSize.width,
                            traceSkip: traceSkip,
                            totalSamples: lineType !== LineType.ZSlice ? metadata.Header.SamplesPerTrace : rotation !== 0 ? getSurveyExtentDistance(metadata) : metadata.Survey3DInfo.TotalXlines,
                        }));
                    }

                    const samplesCacheSystem = getParamsFromUrl().samplesCacheSystem;
                    const imageCacheSystem = getParamsFromUrl().imageCacheSystem;
                    const skipTraceCount = getParamsFromUrl().skipTraceCount;
                    const middleLine = surveyLineStart + Math.ceil((surveyLineEnd - surveyLineStart) / 2);
                    let layerParams: IGeopostSeismicLayerParams = {
                        tenantToken: tenantConfig?.tenantToken,
                        sourceApi: seismic2DDefaults?.color_bar_name ?? urlParams.sourceApi,
                        urlRender: tenantConfig?.endpoints.render,
                        byteSize: metadata.Header.ByteSize,
                        colorbar: response.Colorbar.Name,
                        dpi: dpi,
                        extent: extent,
                        filePath: metadata.VolumePath,
                        isIndexedVolume: metadata.IsIndexed,
                        jpgQuality: jpgQuality,
                        maxSampleValue: metadata.MaxSampleValue,
                        minSampleValue: metadata.MinSampleValue,
                        parallelProcesses: totalParallelProcess,
                        resolutions: resolutions,
                        tileDimensionsPerResolution: tileDimensions,
                        scaleX: scale.x,
                        scaleY: scale.y,
                        skipTraceCount: skipTraceCount,
                        tileHeight: tileSize.height,
                        tileWidth: tileSize.width,
                        volumeToken: metadata.VolumeToken,
                        applyWatermark: restrict?.SeismicWatermark ?? false,
                        surveyInlineStart: metadata.Survey3DInfo.InlineStart,
                        surveyInlineEnd: metadata.Survey3DInfo.InlineEnd,
                        surveyXlineStart: metadata.Survey3DInfo.XlineStart,
                        surveyXlineEnd: metadata.Survey3DInfo.XlineEnd,
                        surveyInlineIncrement: metadata.Survey3DInfo.InlineIncrement,
                        surveyXlineIncrement: metadata.Survey3DInfo.XlineIncrement,
                        numberOfTraces: metadata.Header.NumberOfTraces,
                        absoluteGain: {
                            min: metadata.MinSampleValue,
                            max: metadata.MaxSampleValue
                        },
                        debug: urlParams.debug,
                        increaseLoaded: increaseLoaded,
                        increaseLoading: increaseLoading,
                        resetLoadingControl: () => {
                            updatePercentage(0,0);
                        },
                        accessToken: jwtToken,
                        forceDisableCache: urlParams.forceDisableImageCache,
                        sampleInterval: metadata.Header.SampleInterval,
                        inlineDimensionName: metadata.DimensionNames?.Inline,
                        xlineDimensionName: metadata.DimensionNames?.Xline,
                        sampleDimensionName: metadata.DimensionNames?.Sample,
                        cdpDimensionName: metadata.DimensionNames?.Cdp,
                        samplesCacheSystem,
                        imageCacheSystem,
                        isInverted: needsFlip !== isInverted /* <---- OR Exclusivo */,
                        isAntialiasApplied: isAntialiasApplied,
                        renderLimits: [],
                        lineNumber: lineNumber,
                        volumeSamplesPerTrace: metadata.Header.SamplesPerTrace,
                        verticallyInverted: needsVerticalFlip
                    };

                    if (!tenantConfig?.useSamplesMatrixCache || urlParams.forceDisableSamplesCache) {
                        layerParams.renderLimits?.push([-Infinity, +Infinity]);
                    }

                    if (rangeTrace !== null) {
                        layerParams.traceRangeStart = rangeTrace.initial;
                        layerParams.traceRangeEnd = rangeTrace.end;
                    }

                    setAbsoluteGain({
                        min: metadata.MinSampleValue,
                        max: metadata.MaxSampleValue,
                    });

                    const loadingLayer = new GeopostTileLoadingLayer();

                    const tileSourceStrategy = lineType === LineType.ZSlice ?
                        new ZSliceTileSourceStrategy(tenantConfig!.endpoints.render, metadata.Survey3DInfo.XlineStart!, metadata.Survey3DInfo.XlineIncrement!)
                        :
                        new LineTileSourceStrategy(lineType, tenantConfig!.endpoints.render, handleTileRequestError);

                    const layer = new GeopostSeismicLayer({
                        ...layerParams,
                        loadingLayer: loadingLayer
                    }, tileSourceStrategy, isPerformanceMonitoringEnabled);

                    const overlay = new GeopostSeismicLayer(layerParams, tileSourceStrategy, isPerformanceMonitoringEnabled);
                    overlay.setOpacity(0.15);
                    overlay.setVisible(false);
                    const map = new GeopostMap({
                        maxZoom: restrict?.SeismicMaxZoomLevel ?? false,
                        center: center,
                        extent: extent,
                        resolutions: resolutions,
                        maxTilesLoading: maxTilesLoading,
                        pixelRatio: pixelRatio,
                        zoom: initialZoom,
                        zoomFactor: zoomFactor
                    });

                    const swipe = new GeopostSeismicLayer({
                        ...layerParams,
                        isSwiped: true,
                        swipeValue: 50,
                        geopostMap: map
                    }, tileSourceStrategy, isPerformanceMonitoringEnabled);
                    swipe.setVisible(false);

                    layer.map = map;
                    overlay.map = map;
                    swipe.map = map;
                    map.addLayer(layer);
                    map.addLayer(swipe);
                    map.addLayer(overlay);

                    map.addLayer(loadingLayer);

                    if (!!metadata.Survey3DInfo.DefaultTraceToShow && !lineNumberParam && lineType === LineType.Inline) {
                        const defaultTraceToShowX = getXByTrace(metadata.Survey3DInfo.DefaultTraceToShow, scale.x, surveyLineStart, lineIncrement);
                        map.getView().setCenter([defaultTraceToShowX, center[1]]);
                    }

                    map.on('rendercomplete', () => {
                        layer.clearLoadingBarriers();
                        console.log('render complete, clearing loading stuff from layer');
                    });

                    /*map.on('moveend', () => {
                        loadingLayer.hideLoadingByZoom(map.getView().getZoom());
                    });*/

                    let debugLayer: GeopostSeismicDebugLayer | null = null;
                    if (urlParams.debug) {
                        debugLayer = new GeopostSeismicDebugLayer(layer.tileGrid!);
                        map.addLayer(debugLayer);
                    }

                    map.getView().setRotation(rotation);
                    console.log('ROTATION ====>', rotation);

                    setLayers(layer, debugLayer, overlay, swipe);
                    setMap(map, width, height);
                    setResolutions(resolutions);
                    setSurveyMetadata(metadata);
                    setVolumeToken(metadata.VolumeToken);

                    if (stateRangeTrace.end > metadata.Header?.NumberOfTraces) {
                        setRangeTrace(stateRangeTrace.initial, metadata.Header?.NumberOfTraces);
                    }
                    setColorbar(response.Colorbar);
                    setSeismicCalculator(seismicCalculator);
                    //setLineType(lineType);
                    setLineNumber(lineNumber);
                    setSeismicIsLoading(false);
                    if (tenantConfig?.useSamplesMatrixCache && !urlParams.forceDisableSamplesCache) {
                        prepareRender(layer, layerParams, loadingLayer,true, lineType);
                    }
                }
            }
        }

        if (!mapStartedRef.current) {
            mapStartedRef.current = true;
            start();
        }
    }, [jwtToken]);

    return {
        error: error
    };
}