import { useEffect } from 'react';

import { ICrossSection } from '../models/interfaces/ICrossSection';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { calculateDisplacementByAnnotation } from '../utils/wellCrossSectionAnnotationsUtils';

export function useAdjustFlatAnnotation(crossSection: ICrossSection | null){
    const { setDisplacementVertical, factorVertical } = useCrossSectionStore(state => ({
        factorVertical: state.factorVertical,
        setDisplacementVertical: state.setDisplacementVertical
    }));

    const {flatAnnotation, annotations } = useCrossSectionAnnotationsStore(state => ({
        annotations: state.annotations,
        flatAnnotation: state.flatAnnotation
    }));

    useEffect(() => {
        if (flatAnnotation && crossSection){
            const displacements = calculateDisplacementByAnnotation(flatAnnotation, annotations, crossSection, factorVertical);

            if (displacements){
                setDisplacementVertical(displacements);
            }
        }
        else {
            setDisplacementVertical({});
            //TODO -> LIMPAR
            //TODO -> E SE TROCAR O PERFIL COMPOSTO?
        }
    }, [flatAnnotation, factorVertical]);
}