import * as React from 'react';
import { SVGProps } from 'react';
const SvgRange = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 700 700"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        {...props}
    >
        <path d="M449.12 333.64c-3.36-3.36-7.281-4.48-11.199-4.48-3.922 0-7.84 1.68-11.199 4.48-6.16 6.16-6.16 16.238 0 22.398l21.281 21.281h-196l21.281-21.281c6.16-6.16 6.16-16.238 0-22.398s-16.238-6.16-22.398 0l-48.168 48.16c-6.16 6.16-6.16 16.238 0 22.398l48.16 48.16c3.36 3.36 7.281 4.48 11.199 4.48 3.922 0 7.84-1.68 11.199-4.48 6.16-6.16 6.16-16.238 0-22.398l-21.281-21.281h196l-21.281 21.281c-6.16 6.16-6.16 16.238 0 22.398s16.238 6.16 22.398 0l48.16-48.16c6.16-6.16 6.16-16.238 0-22.398zM165.2 333.84h-17.922c-3.922 0-6.719 2.8-6.719 6.719v110.88c0 3.922 2.801 6.719 6.719 6.719l17.922.004c3.922 0 6.719-2.801 6.719-6.72l.004-110.88c0-3.917-3.364-6.718-6.723-6.718zM553.28 333.84H534.8c-3.922 0-6.719 2.8-6.719 6.719v110.88c0 3.922 2.801 6.719 6.719 6.719h17.922c3.922 0 6.719-2.801 6.719-6.72v-110.88c.558-3.917-2.801-6.718-6.16-6.718zM609.28 230.16H90.72c-3.922 0-6.719 2.8-6.719 6.719V254.8c0 3.922 2.801 6.719 6.719 6.719h49.281v38.078c0 3.921 2.8 6.718 6.719 6.718l18.48.004c3.922 0 6.719-2.8 6.719-6.718v-38.078h45.922v38.078c0 3.921 2.8 6.718 6.718 6.718h17.922c3.922 0 6.72-2.8 6.72-6.718v-38.078h45.921v38.078c0 3.921 2.801 6.718 6.719 6.718h17.922c3.922 0 6.719-2.8 6.719-6.718v-38.078h45.922v38.078c0 3.921 2.8 6.718 6.719 6.718h17.922c3.921 0 6.718-2.8 6.718-6.718v-38.078l47.04-.004v38.078c0 3.921 2.8 6.718 6.718 6.718h17.922c3.922 0 6.719-2.8 6.719-6.718V261.52h45.922v38.078c0 3.921 2.8 6.718 6.719 6.718h17.922c3.922 0 6.719-2.8 6.719-6.718V261.52h49.28c3.922 0 6.72-2.801 6.72-6.72V236.88c.558-3.36-2.243-6.719-6.16-6.719z" />
    </svg>
);
export default SvgRange;
