import * as React from 'react';
import { SVGProps } from 'react';
const SvgReset = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        fill="currentColor"
        {...props}
    >
        <path d="M237.5.6c-25.4 2.6-50.2 7.9-69 14.9-23.3 8.6-52.9 25.2-71 39.8-3.8 3.1-7.4 5.7-8 5.7-.5 0-11.8-10.9-25.2-24.2C47.4 20 40 13.2 40 14.5c0 1.1-2.2 38.9-4.9 84s-4.7 82.2-4.5 82.4c.4.5 152.8-10.5 164.7-11.8l5.8-.6-24.1-22.4-24.1-22.3 5.8-4.2c35.5-25.6 80-36.1 124.3-29.1 24.6 3.9 49.9 14.3 70.9 29.3 12.1 8.6 29.7 26.2 38.3 38.3 11.9 16.6 21.9 38.3 26.7 57.4 3.4 13.8 4.5 23.5 4.5 40.5 0 29.5-5.2 51.1-18.6 77-8.1 15.8-17.3 28.4-30.4 41.4-13 13.1-25.5 22.3-41.4 30.5-25.2 13-48.2 18.5-77 18.5-34.9-.1-64.4-9-93.2-28.3-16.1-10.8-34.7-29.3-45.8-45.6-10.6-15.6-19.8-36.9-24.1-55.8-1.7-7.8-4.9-31.1-4.9-35.9 0-1.7-2.7-1.8-44.1-1.8H-.2l.7 8.7c3.3 45.2 11.1 74.4 28.9 108.9 12.9 25 27.1 44.2 47.5 64.4 39.8 39.3 87.6 63 144.8 71.7 13.7 2.1 52.7 2.4 65.8.5 59.3-8.5 109-32.9 149.4-73.3 40.7-40.7 65.6-91.6 73.3-149.9 1.6-12.3 1.6-49.7 0-62-7.7-58.3-32.6-109.2-73.3-149.9C397.5 35.7 348.6 11.2 292 2.5 282 1 246.2-.3 237.5.6z" />
    </svg>
);
export default SvgReset;
