import * as React from 'react';
import { SVGProps } from 'react';
const SvgMetadata = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 200 200"
        fill="currentColor"
        {...props}
    >
        <path d="M55 12.7c-.8.3-2.3 1.2-3.2 2.1-1.6 1.4-1.8 4.1-1.8 28.9 0 26 .1 27.4 2 29.3 2.6 2.6 6.2 2.5 8.6-.1 1.7-1.9 1.9-4.1 2.2-25l.3-22.9H175v150h-60.5c-59.2 0-60.5 0-62.5 2-2.6 2.6-2.5 6.2.1 8.6 2 1.8 4.6 1.9 67 1.9 60.9 0 64.9-.1 66.6-1.8 1.7-1.7 1.8-6.5 1.8-85.7s-.1-84-1.8-85.7c-1.7-1.7-5.8-1.8-65.5-2-35-.1-64.4.1-65.2.4z" />
        <path d="M76.6 46.2c-.9 1.2-1.6 2.9-1.6 3.8 0 .9.7 2.6 1.6 3.8l1.5 2.2h40.4c44.3 0 43.5.1 43.5-6s.8-6-43.5-6H78.1l-1.5 2.2zM89.6 70.6c-2 2-2 6.8 0 8.8 1.3 1.4 6 1.6 35.4 1.6 37.2 0 37 0 37-6s.2-6-37-6c-29.4 0-34.1.2-35.4 1.6zM14.9 82.9l-2.4 1.9v80.4l2.4 1.9c2.2 1.8 4.3 1.9 32.2 1.9 28.6 0 30-.1 31.9-2 1.9-1.9 2-3.3 2-42s-.1-40.1-2-42c-1.9-1.9-3.3-2-31.9-2-27.9 0-30 .1-32.2 1.9zM69 125v31H25V94h44v31z" />
        <path d="M33.7 101.2c-2.7 2.1-3.1 6.1-.8 8.9 1.8 2.3 2.4 2.4 13.9 2.4 13.4 0 15.2-.7 15.2-6 0-5.6-2.1-6.5-15.1-6.5-7.7 0-12.1.4-13.2 1.2zM33 121c-1.1 1.1-2 2.9-2 4s.9 2.9 2 4c1.8 1.8 3.3 2 13.9 2 13.2 0 15.1-.7 15.1-6s-1.9-6-15.1-6c-10.6 0-12.1.2-13.9 2zM35.3 138c-2.3.9-4.3 3.9-4.3 6.2 0 .8.9 2.4 2 3.6 1.9 2 3 2.2 13.9 2.2 10.2 0 12-.3 13.4-1.8 2.2-2.4 2.2-7.6.1-9.3-2-1.6-21.7-2.4-25.1-.9zM95.7 95.8c-2.2 2.4-2.1 6.5.1 8.5 1.6 1.5 5.4 1.7 32.5 1.7 33.6 0 33.7 0 33.7-6s-.1-6-33.9-6c-28.6 0-30.9.1-32.4 1.8zM95.7 120.8c-2.2 2.4-2.1 6.5.1 8.5 1.6 1.5 5.4 1.7 32.5 1.7 33.6 0 33.7 0 33.7-6s-.1-6-33.9-6c-28.6 0-30.9.1-32.4 1.8zM95.7 145.8c-2.2 2.4-2.1 6.5.1 8.5 1.6 1.5 5.4 1.7 32.5 1.7 33.6 0 33.7 0 33.7-6s-.1-6-33.9-6c-28.6 0-30.9.1-32.4 1.8z" />
    </svg>
);
export default SvgMetadata;
