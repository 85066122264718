import * as React from 'react';
import { SVGProps } from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 557 608"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        {...props}
    >
        <path d="M197 240V37h112v203h194v104H309v203H197V344H0V240h197z" />
    </svg>
);
export default SvgPlus;
