import LayerTile from 'ol/layer/Tile';
import SourceTileDebug from 'ol/source/TileDebug';
import TileGrid from 'ol/tilegrid/TileGrid';

export class GeopostSeismicDebugLayer extends LayerTile<SourceTileDebug> {
    constructor(tileGrid: TileGrid){
        super();

        this.setSource(this.buildSource(tileGrid));
    }

    private buildSource(tileGrid: TileGrid) {
        const source = new SourceTileDebug({ tileGrid: tileGrid });

        return source;
    }

    updateTileGrid(tileGrid: TileGrid){
        const source = this.buildSource(tileGrid);
        this.setSource(source);
    }
}